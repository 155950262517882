<template>
  <SingleCustomer />
</template>
<script>
  import SingleCustomer from "@/components/Customers/SingleCustomer";
  export default {
    components: {
      SingleCustomer,
    },
  };
</script>
